import { TRACK_SOURCE_TYPE } from '../constants/trackSourceType';

const createTrackSources = (selectedSources) => {
  return selectedSources.reduce((acc, { source, sourceShort, country_tag }) => {
    acc[source] = {
      source,
      sourceShort,
      country_tag,
      status: TRACK_SOURCE_TYPE.MISSING,
    };
    return acc;
  }, {});
};

export const prepTracksWithSources = ({ tracks, selectedSources }) => {
  let tracksWithSources = {};
  for (const track of tracks) {
    if (!(track.title in tracksWithSources)) {
      tracksWithSources[track.title] = {
        track,
        sources: createTrackSources(selectedSources),
      };
    }
    const currentTrackSources = tracksWithSources[track.title].sources;
    const currentTrackSourceValue = currentTrackSources[track.source];

    try {
      if (currentTrackSourceValue.status === TRACK_SOURCE_TYPE.MISSING) {
        currentTrackSources[track.source].status = TRACK_SOURCE_TYPE.FOUND;
      } else if (currentTrackSourceValue.status === TRACK_SOURCE_TYPE.FOUND) {
        currentTrackSources[track.source].status =
          TRACK_SOURCE_TYPE.POTENTIAL_DUPLICATE;
      }
    } catch (error) {
      continue;
    }
  }

  return tracksWithSources;
};
