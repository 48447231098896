<template>
  <ul :class="$style.legend">
    <li :class="$style.legendItem">
      <SourceStatus status="MISSING" /> MISSING
    </li>
    <li :class="$style.legendItem">
      <SourceStatus status="FOUND" v-bind="foundStatusClasses" /> FOUND
    </li>
    <li :class="$style.legendItem">
      <SourceStatus status="POTENTIAL_DUPLICATE" /> POTENTIAL DUPLICATE
    </li>
  </ul>
</template>

<script>
import SourceStatus from "./SourceStatus";

export default {
  name: "StatusLegend",
  components: {
    SourceStatus,
  },
  props: {
    foundStatusClasses: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" module>
.legend {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 1.5rem;
}

.legendItem {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-weight: 500;
  font-family: $primary-font;
  font-size: 14px;
}
</style>