<template>
  <div>
    <div :class="$style.tableView" ref="tableView">
      <div :class="$style.tableContainer" ref="tableContainer">
        <div :class="$style.tableMarketTabs">
          <BaseButton
            :class="[
              $style.marketTypeButton,
              selectedMarket === MARKET_TYPE.ALL && $style.inactiveMarket,
            ]"
            @click.prevent="selectedMarket = MARKET_TYPE.TOP"
          >
            TOP MARKETS
          </BaseButton>
          <BaseButton
            :class="[
              $style.marketTypeButton,
              selectedMarket === MARKET_TYPE.TOP && $style.inactiveMarket,
            ]"
            @click.prevent="selectedMarket = MARKET_TYPE.ALL"
          >
            ALL MARKETS
          </BaseButton>
        </div>
        <table :class="$style.tracksTable">
          <thead
            :class="[
              $style.tableHead,
              tableViewVerticalScrolled && $style.scrolled,
            ]"
          >
            <tr ref="tableHeadRow" :class="[$style.tableRow]">
              <th
                :class="$style.tableHeading"
                v-for="{ source, sourceShort } of selectedSourcesTableHeaders"
                :key="source"
                :title="source"
              >
                {{ sourceShort }}
              </th>
            </tr>
          </thead>
          <SourcesTableBody
            :tbodyClass="$style.tableBody"
            :tracks="tracksWithSources"
            :tracksBySource="tracksBySource"
            @openCountryDetails="$emit('openCountryDetails', $event)"
          />
        </table>
        <div ref="loadMoreColumns"></div>
      </div>
    </div>
    <footer v-show="tracks" :class="$style.footer">
      <router-link
        :class="$style.viewLink"
        :to="`/catalogs/${catalogId}/search/map`"
        ><BaseIcon :class="$style.viewLinkIcon" icon="map" />Map
        View</router-link
      >

      <StatusLegend />
    </footer>
  </div>
</template>
<script>
import SourceStatus from "./components/SourceStatus";
import SourcesTableBody from "./components/SourcesTableBody";
import StatusLegend from "./components/StatusLegend";
import { syncScroll } from "@/services/syncScroll";
import { prepTracksWithSources } from "../helpers/prepTracksWithSources";
const MARKET_TYPE = {
  ALL: "ALL",
  TOP: "TOP",
};

const MARKET_ALL_PER_PAGE = 15;

export default {
  name: "CatalogTableView",
  components: {
    SourceStatus,
    SourcesTableBody,
    StatusLegend,
  },
  props: {
    tracks: {
      type: Array,
      default: () => [],
    },
    distinctTracks: {
      type: Array,
      default: () => [],
    },
    tracksBySource: {
      type: Object,
      default: () => ({}),
    },
    catalogId: {
      type: String,
      required: true,
    },
    sources: {
      type: Array,
      default: () => [],
    },
    topSources: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedMarket: MARKET_TYPE.TOP,
      allSourcesPage: 1,
      tracksWithSources: {},
      tableViewVerticalScrolled: false,
    };
  },
  created() {
    this.MARKET_TYPE = MARKET_TYPE;
    this.cachedTracks = {
      top: null,
      all: null,
    };
  },
  mounted() {
    this.$refs.tableView.addEventListener(
      "scroll",
      this.onTableViewVerticalScroll
    );

    this.scrollSyncInstance = syncScroll(
      this.$refs.tableContainer,
      this.$refs.tableHeadRow
    );
  },
  computed: {
    selectedSources() {
      return this.selectedMarket === MARKET_TYPE.ALL
        ? this.sources
        : this.sources.filter(({ top_market }) => top_market === "Yes");
    },
    selectedSourcesTableHeaders() {
      return this.selectedMarket === MARKET_TYPE.ALL
        ? this.sources.slice(0, this.allSourcesPage * MARKET_ALL_PER_PAGE)
        : this.sources.filter(({ top_market }) => top_market === "Yes");
    },
    tracksWithAllSourcesSlice() {
      let tracks = {};
      const limit = this.allSourcesPage * 15;
      let counter = 0;
      for (const key in this.tracksWithAllSources) {
        if (counter === limit) break;
        tracks[key] = this.tracksWithAllSources[key];
        counter++;
      }

      return tracks;
    },
  },
  watch: {
    async selectedMarket(market) {
      this.$refs.tableContainer.scrollLeft = 0;
      this.prepareTracks(market);
    },
    tracks: {
      immediate: true,
      async handler() {
        if (!this.tracks.length) return;
        this.cachedTracks = {
          top: null,
          all: null,
        };
        this.prepareTracks(this.selectedMarket);
        await this.$nextTick();
        this.initLoadMoreColumns();
      },
    },
  },
  beforeDestroy() {
    this.loadMoreObserver?.disconnect();
    this.$refs.tableView.removeEventListener(
      "scroll",
      this.onTableViewVerticalScroll
    );

    this.scrollSyncInstance?.cleanup?.();
  },
  methods: {
    onTableViewVerticalScroll(e) {
      this.tableViewVerticalScrolled = e.target.scrollTop > 0;
    },
    initLoadMoreColumns() {
      const $target = this.$refs.loadMoreColumns;
      let options = {
        root: this.$refs.tableContainer,
        rootMargin: "0px",
        threshold: 1.0,
      };

      const onIntersect = (entries) => {
        entries.forEach((entry) => {
          if (this.selectedMarket === MARKET_TYPE.TOP) return;
          this.allSourcesPage++;
          this.tracksWithSources = this.getTracksWithSlicedSources(
            this.cachedTracks.all
          );
        });
      };

      const observer = new IntersectionObserver(onIntersect, options);
      this.loadMoreObserver = observer;

      observer.observe($target);
    },
    prepareTracks(market) {
      if (market === MARKET_TYPE.TOP) {
        this.allSourcesPage = 1;

        if (this.cachedTracks.top) {
          this.tracksWithSources = this.cachedTracks.top;
          return;
        }

        const topTracks = prepTracksWithSources({
          tracks: this.tracks,
          selectedSources: this.selectedSources,
        });
        this.tracksWithSources = topTracks;
        this.cachedTracks.top = topTracks;
        return;
      }

      const allTracks = prepTracksWithSources({
        tracks: this.tracks,
        selectedSources: this.selectedSources,
      });
      this.cachedTracks.all = allTracks;
      this.tracksWithSources = this.getTracksWithSlicedSources(allTracks);
    },
    getTracksWithSlicedSources(tracksWithSources) {
      let tracks = {};
      const limit = this.allSourcesPage * MARKET_ALL_PER_PAGE;

      for (const key in tracksWithSources) {
        tracks[key] = {
          ...tracksWithSources[key],
          sources: this.getSlicedSources(tracksWithSources[key].sources, limit),
        };
      }

      return tracks;
    },
    getSlicedSources(allSources, limit) {
      const sources = {};
      let counter = 0;

      for (const key in allSources) {
        if (counter === limit) break;
        sources[key] = allSources[key];
        counter++;
      }

      return sources;
    },
  },
};
</script>
<style lang="scss" module>
.tableMarketTabs {
  position: fixed;
  z-index: 60;
  left: 5rem;
  transform: translateY(0.8rem);
}
.marketTypeButton {
  padding: 0 !important;
  font-size: 1rem !important;

  &:first-of-type {
    margin-right: 2rem;
  }
}

.inactiveMarket {
  color: $color-grey-light #{!important};
}

.tableView {
  position: relative;
  height: calc(100vh - 15rem);
  overflow-y: auto;
}

.tableContainer {
  width: calc(100vw - 10rem - 360px);
  overflow-x: scroll;
  margin-left: 360px;
  display: flex;
  align-items: stretch;
}

.tracksTable {
  padding: 0;
}

.tableBody {
  & > tr:first-child {
    margin-top: 48px;
  }
}

.tableHead {
  position: fixed;
  top: 130px;
  left: calc(5rem);
  z-index: 50;
  // background: white;
  transform: translateY(-10px);
  max-width: calc(100vw - 10rem);
  &.scrolled {
    background: white;
  }

  .tableRow {
    margin-left: 360px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  // position: sticky;
  // top: 0;
}

// .tableHead {
//   height: 48px;
//   .tableRow {
//     position: fixed;
//     left: 5rem;
//     z-index: 5;
//     transform: translateY(-5px);
// &.scrolled {
//   background: white;
// }

// .tableHeading:nth-child(2) {
//   margin-left: 360px;
// }
//   }
// }

.tableRow {
  display: flex;
  height: 48px;
}

.tableHeading {
  font-size: 14px;
  color: #000;
  font-weight: 500;
  width: 100px;
  white-space: nowrap;
  text-align: center;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0 2px;
  flex-shrink: 0;

  // &:first-child {
  //   margin-left: 360px;
  // }
}

.tableCell {
  font-size: 14px;
  white-space: nowrap;
  padding: 0 2px;

  &:not(.tableFirstCol) {
    width: 100px;
    display: flex;
    justify-content: center;
  }
}

.tableFirstCol {
  // width: 360px;
  // font-weight: 500;
  // position: fixed;
  // top: auto;
  // left: 0;
  // display: flex;
  // align-items: center;
  // justify-content: flex-start;
  // height: 48px;
  // z-index: 50;
}

.footer {
  margin: 3rem 5rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.viewLink {
  display: block;
  color: $color-grey-light;

  &:hover {
    color: #333;
  }
}

.viewLinkIcon {
  margin-right: 0.5rem;
}
</style>