var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"tableView",class:_vm.$style.tableView},[_c('div',{ref:"tableContainer",class:_vm.$style.tableContainer},[_c('div',{class:_vm.$style.tableMarketTabs},[_c('BaseButton',{class:[
            _vm.$style.marketTypeButton,
            _vm.selectedMarket === _vm.MARKET_TYPE.ALL && _vm.$style.inactiveMarket ],on:{"click":function($event){$event.preventDefault();_vm.selectedMarket = _vm.MARKET_TYPE.TOP}}},[_vm._v(" TOP MARKETS ")]),_c('BaseButton',{class:[
            _vm.$style.marketTypeButton,
            _vm.selectedMarket === _vm.MARKET_TYPE.TOP && _vm.$style.inactiveMarket ],on:{"click":function($event){$event.preventDefault();_vm.selectedMarket = _vm.MARKET_TYPE.ALL}}},[_vm._v(" ALL MARKETS ")])],1),_c('table',{class:_vm.$style.tracksTable},[_c('thead',{class:[
            _vm.$style.tableHead,
            _vm.tableViewVerticalScrolled && _vm.$style.scrolled ]},[_c('tr',{ref:"tableHeadRow",class:[_vm.$style.tableRow]},_vm._l((_vm.selectedSourcesTableHeaders),function(ref){
          var source = ref.source;
          var sourceShort = ref.sourceShort;
return _c('th',{key:source,class:_vm.$style.tableHeading,attrs:{"title":source}},[_vm._v(" "+_vm._s(sourceShort)+" ")])}),0)]),_c('SourcesTableBody',{attrs:{"tbodyClass":_vm.$style.tableBody,"tracks":_vm.tracksWithSources,"tracksBySource":_vm.tracksBySource},on:{"openCountryDetails":function($event){return _vm.$emit('openCountryDetails', $event)}}})],1),_c('div',{ref:"loadMoreColumns"})])]),_c('footer',{directives:[{name:"show",rawName:"v-show",value:(_vm.tracks),expression:"tracks"}],class:_vm.$style.footer},[_c('router-link',{class:_vm.$style.viewLink,attrs:{"to":("/catalogs/" + _vm.catalogId + "/search/map")}},[_c('BaseIcon',{class:_vm.$style.viewLinkIcon,attrs:{"icon":"map"}}),_vm._v("Map View")],1),_c('StatusLegend')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }